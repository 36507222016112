
export function TextContacto() {
  return (
    <div className="container-text-contacto">
        <div className="text-contacto">
            <p>Actualmente la comercialización exige mucho más que un buen prodcuto o servicio, exige tambien estar en permanente comunicación con el mercado actual. Para que tú objetivo alcance los mejores resultados <br /> <span>MOSTRA TÚ MARCA</span></p>
        </div>
    </div>
  )
}
