
export function TextServicio() {
  return (
    <div className="container-text-servicio">
        <div className="text-servicio">
            <h5>SERVICIO OFRECIDO:</h5>
            <p className="p-servicio">A traves de su área de publicidad digital "LUSSOVISION", las empresas muestran su marca en las pantallas led's ubicadas estrategicamente en la via publica exhibiendo en spot's publicitarios sus productos o servicios.</p>
        </div>
    </div>
  )
}
