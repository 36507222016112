
export  function TextHome() {
  return (
    <div className= "container-text-home">
        <div className= "text-home">
            <h4>LUSSO PUBLICIDAD</h4 >
            <p>
                Es una empresa fundada en el año 2002.
                Dedicada a la comunicacion publicitaria en via publica.
                En el año 2009 es la primer empresa en zona sur de GBA
                en instalar pantallas digitales led de gran tamaño
                para exibicion publicitaria.
            </p>
        </div>
    </div>
  )
}
